import { AuthenticationService } from "./services/authentication-service";
import { autoinject } from "aurelia-framework";

@autoinject()
export class LoginRegister {
    private _authenticationService: AuthenticationService;

    currentView: string = "Login";

    loginEmail: string = "";
    loginPassword: string = "";
    loginError: string = "";

    registerFirstName: string = "";
    registerLastName: string = "";
    registerEmail: string = "";
    registerMobile: number = 0;
    registerPassword: string = "";
    registerPasswordRepeat: string = "";

    isLoggingIn: boolean = false;

    constructor(authenticationService: AuthenticationService) {
        this._authenticationService = authenticationService;
    }

    attached() {
        this.resetLoginDetails();
    }

    async login() {
        if (!this.loginEmail || !this.loginPassword) {
            this.loginError = "Please enter an username and password";
            return;
        }

        this.isLoggingIn = true;

        if (this.loginEmail && this.loginPassword)
            var loginSucceeded = await this._authenticationService.loginAsync(
                this.loginEmail,
                this.loginPassword
            );

        if (loginSucceeded == "Unknown User") {
            this.loginError = "Invalid username or password";
            this.loginPassword = "";
            this.isLoggingIn = false;
        }
        if (loginSucceeded == "Login Successful") {
            this.isLoggingIn = false;
            this.loginError = "";
            this.loginPassword = "";
            this.loginEmail = "";
        }
    }

    async registerNewUser() {
        this.loginError = null;
        if (!this.registerFirstName) {
            this.loginError = "Please enter a first name";
            return;
        }
        if (!this.registerLastName) {
            this.loginError = "Please enter a last name";
            return;
        }
        if (!this.registerEmail) {
            this.loginError = "Please enter an email";
            return;
        }
        if (!this.registerMobile) {
            this.loginError = "Please enter a phone number";
            return;
        }
        if (!this.registerPassword || !this.registerPasswordRepeat) {
            this.loginError = "Please enter a password";
            return;
        }
        if (!this.registerPassword || !this.registerPasswordRepeat) {
            this.loginError = "The passwords do not match";
            return;
        }
        var registerResult = await this._authenticationService.registerAsync(
            this.registerFirstName,
            this.registerLastName,
            this.registerEmail,
            this.registerMobile,
            this.registerPassword,
            this.registerPasswordRepeat,
            "00000000-0000-0000-0000-000000000000"
        );
        this.currentView = "Login";
    }

    resetLoginDetails() {
        this.loginPassword = "";
    }

    clearErrorMessage() {
        if (!this.isLoggingIn) this.loginError = "";
    }
}
