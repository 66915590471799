import { bindable, bindingMode } from "aurelia-framework";

export class ObjectSelectElement {
    @bindable isEditing: boolean;
    @bindable isEditable: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    options: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    selectedOption: any;
    @bindable displayKey: boolean;
    @bindable isDropup: boolean = false;
    @bindable unassignable: boolean = false;
    @bindable darkUi: boolean;

    dropdownExpanded: boolean = false;

    selectOption(option) {
        if (option == this.selectedOption) return;
        this.selectedOption = option;
        this.dropdownExpanded = false;
    }
    toggleDropdown() {
        this.dropdownExpanded = !this.dropdownExpanded;
    }
    unassignObject() {
        this.selectedOption = null;
        this.dropdownExpanded = false;
    }
}
