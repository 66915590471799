import { IAssetGroup } from "models/interfaces";

export class AssetGroupValueConverter {
    fromView(value) {
        switch (value) {
            case "Vehicles":
                return IAssetGroup.Vehicles;
            case "Structures":
                return IAssetGroup.Structures;
            case "Jumpers":
                return IAssetGroup.Jumpers;
            case "AisVessels":
                return IAssetGroup.AisVessels;
            case "Pipelines":
                return IAssetGroup.Pipelines;
            default:
                return IAssetGroup.Unknown;
        }
    }

    toView(value) {
        switch (value) {
            case IAssetGroup.Vehicles:
                return "Vehicles";
            case IAssetGroup.Structures:
                return "Structures";
            case IAssetGroup.Jumpers:
                return "Jumpers";
            case IAssetGroup.AisVessels:
                return "AisVessels";
            case IAssetGroup.Pipelines:
                return "Pipelines";
            default:
                return "Unknown";
        }
    }
}
