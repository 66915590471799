import { StateManager } from "./services/state-manager";
import { State } from "./services/state";
import { IProject } from "./models/interfaces";
import { DialogService } from "aurelia-dialog";
import { autoinject, bindable } from "aurelia-framework";
import {
	addSurveyLine,
	updateOrganizations,
	updateVideos,
	updateProject,
	updateSurveyLine,
	updateSurveyLines,
	updateUser,
	updateWaypoint,
	updateWaypoints,
	deleteSurveyLine,
	updateProjects,
	updateTerrains,
	updateAssets,
	updatePolylines,
	updateAsset,
	updateRightSidebar,
	updatePolyline,
	updateAisData,
	setMapVisibility,
	setTerrainVisibility,
	updateBroadcasts,
	updateVideo,
	updateOrganization,
	updateFleets,
	updateFleet,
	updateUserAssets,
	setDarkMode,
	clearState,
	updateUsers,
	setTheaterMode,
} from "./services/state-actions";
import { connectTo, Store } from "aurelia-store";
import { PLATFORM } from "aurelia-pal";
import { Router } from "aurelia-router";
import { pluck } from "rxjs/operators";
import { AuthenticationService } from "services/authentication-service";

@autoinject()
@connectTo({
	selector: {
		project: (store) => store.state.pipe(pluck("project")),
		rightSidebarOpen: (store) =>
			store.state.pipe(pluck("rightSidebarOpen")),
	},
})
export class App {
	router: Router;
	currentProject: IProject;
	_store: Store<State>;
	_stateManager: StateManager;
	_dialogService: DialogService;
	private _authenticationService: AuthenticationService;
	contentWrapperHeight = window.innerHeight - 56;

	isRightSidebarOpen: number = 0;

	leftSidebarOpen: boolean = false;

	constructor(
		store: Store<State>,
		stateManager: StateManager,
		dialogService: DialogService,
		_authenticationService: AuthenticationService
	) {
		this._store = store;

		this._store.registerAction("updateOrganization", updateOrganization);

		this._store.registerAction("updateProject", updateProject);
		this._store.registerAction("updateProjects", updateProjects);

		this._store.registerAction("updateTerrains", updateTerrains);

		this._store.registerAction("updateAssets", updateAssets);
		this._store.registerAction("updateAsset", updateAsset);

		this._store.registerAction("updatePolylines", updatePolylines);
		this._store.registerAction("updatePolyline", updatePolyline);

		this._store.registerAction("updateOrganizations", updateOrganizations);
		this._store.registerAction("updateWaypoints", updateWaypoints);
		this._store.registerAction("updateWaypoint", updateWaypoint);
		this._store.registerAction("updateSurveyLines", updateSurveyLines);
		this._store.registerAction("addSurveyLine", addSurveyLine);
		this._store.registerAction("updateSurveyLine", updateSurveyLine);
		this._store.registerAction("updateUser", updateUser);
		this._store.registerAction("updateUsers", updateUsers);
		this._store.registerAction("updateUserAssets", updateUserAssets);
		this._store.registerAction("deleteSurveyLine", deleteSurveyLine);
		this._store.registerAction("updateRightSidebar", updateRightSidebar);
		this._store.registerAction("updateAisData", updateAisData);
		this._store.registerAction("setMapVisibility", setMapVisibility);
		this._store.registerAction(
			"setTerrainVisibility",
			setTerrainVisibility
		);
		this._store.registerAction("updateVideos", updateVideos);
		this._store.registerAction("updateVideo", updateVideo);
		this._store.registerAction("updateFleets", updateFleets);
		this._store.registerAction("updateFleet", updateFleet);
		this._store.registerAction("updateBroadcasts", updateBroadcasts);

		this._store.registerAction("setDarkMode", setDarkMode);
		this._store.registerAction("setTheaterMode", setTheaterMode);
		this._store.registerAction("clearState", clearState);

		this._stateManager = stateManager;
		this._dialogService = dialogService;
	}

	projectChanged(newProject, oldProject) {
		if (newProject == null) return;

		if (newProject.projectId == oldProject?.projectId) return;

		this.currentProject = newProject;
	}

	rightSidebarOpenChanged(
		newRightSidebarOpen: boolean,
		oldRightSidebarOpen: boolean
	) {
		if (newRightSidebarOpen == oldRightSidebarOpen) return;

		this.isRightSidebarOpen = newRightSidebarOpen ? 1 : 0;
	}

	async loadProjectWithData() {
		// this._dialogService.open({
		// 	viewModel: LoadingModal,
		// 	model: project.name,
		// });

		const fetchTerrainsTask = this._stateManager
			.getTerrainsAsync
			// project.projectId
			();
		const fetchAssetsTask = this._stateManager
			.getAssetsAsync
			// project.projectId
			();
		const fetchPolylinesTask = this._stateManager
			.getPolylinesAsync
			// project.projectId
			();
		// const fetchSurveyLinesTask = this._stateManager
		//     .getSurveyLinesAsync
		//     // project.projectId
		//     ();
		// const fetchWaypointsTask = this._stateManager
		//     .getWaypointsAsync
		//     // project.projectId
		//     ();
		// const fetchAisDataTask = this._stateManager
		//     .getAisData
		//     // project.projectId
		//     ();
		const fetchVideoDataTask = this._stateManager.getVideosAsync({
			// projectId: project.projectId,
		});
		const fetchBroadcastsDataTask = this._stateManager.getBroadcastsAsync(
			{}
		);
		// console.log("Loading Project Data for " + project.name + " ...");

		await Promise.allSettled([
			fetchTerrainsTask,
			fetchAssetsTask,
			fetchPolylinesTask,
			// fetchSurveyLinesTask,
			// fetchWaypointsTask,
			// fetchAisDataTask,
			fetchVideoDataTask,
			fetchBroadcastsDataTask,
		]);

		// console.log("Project Data Loaded for " + project.name + " ...");

		window.setTimeout(() => {
			if (this._dialogService.hasActiveDialog) {
				console.log("Closing Loading Modal");
				this._dialogService.closeAll();
			}
		}, 1000);
	}

	created() {
		this._stateManager.getProjectsAsync(null);
		this._stateManager.getOrganizationsAsync();
		this._stateManager.getOrganizationAsync();
		this._stateManager.getUserAsync();

		this.loadProjectWithData();
	}

	async configureRouter(config, router) {
		this.router = router;

		config.title = "4DNav";

		var user = await this._stateManager.getUserAsync();

		var routes = [
			{
				route: ["", "videos"],
				name: "videos",
				settings: {
					icon: "video",
					color: "blue",
					isExpanded: false,
					subPages: [],
				},
				moduleId: PLATFORM.moduleName("./pages/videos"),
				nav: true,
				title: "Videos",
				config: {},
			},
			{
				route: ["assets"],
				name: "assets",
				settings: {
					icon: "ship",
					color: "blue",
					isExpanded: false,
					subPages: [],
				},
				moduleId: PLATFORM.moduleName("./pages/assets"),
				nav: false,
				title: "Assets",
				config: {},
			},
			// {
			// 	route: ["assets"],
			// 	name: "assets",
			// 	settings: {
			// 		icon: "pipe-collar",
			// 		color: "blue",
			// 		isExpanded: false,
			// 		subPages: [],
			// 	},
			// 	moduleId: PLATFORM.moduleName("./pages/assets"),
			// 	nav: false,
			// 	title: "Assets",
			// 	config: {},
			// },
			{
				route: ["fleets"],
				name: "fleets",
				settings: {
					icon: "earth-americas",
					color: "blue",
					isExpanded: false,
					subPages: [],
				},
				moduleId: PLATFORM.moduleName("./pages/fleets"),
				nav: false,
				title: "Fleets",
				config: {},
			},
			{
				route: ["map-layers"],
				name: "map-layers",
				settings: {
					icon: "earth-americas",
					color: "blue",
					isExpanded: false,
					subPages: [],
				},
				moduleId: PLATFORM.moduleName("./pages/settings-pages/mapping-layers"),
				nav: false,
				title: "Map Layers",
				config: {},
			},
			{
				route: ["user-roles"],
				name: "User Roles",
				settings: {
					icon: "users",
					color: "blue",
					isExpanded: false,
					subPages: [],
				},
				moduleId: PLATFORM.moduleName("./pages/user-roles"),
				nav: false,
				title: "User Roles",
				config: {},
			},
			// {
			// 	route: ["2dmaps"],
			// 	name: "2D Maps",
			// 	settings: {
			// 		icon: "map",
			// 		color: "blue",
			// 		isExpanded: false,
			// 		subPages: [],
			// 	},
			// 	moduleId: PLATFORM.moduleName("./pages/2dmaps"),
			// 	nav: true,
			// 	title: "2D Maps",
			// 	config: {},
			// },
			// {
			// 	route: ["3dmaps"],
			// 	name: "3D Maps",
			// 	settings: {
			// 		icon: "globe",
			// 		color: "blue",
			// 		isExpanded: false,
			// 		subPages: [],
			// 	},
			// 	moduleId: PLATFORM.moduleName("./pages/3dmaps"),
			// 	nav: true,
			// 	title: "3D Maps",
			// 	config: {},
			// },
			// {
			// 	route: ["panel-grid"],
			// 	name: "panel-grid",
			// 	settings: {
			// 		icon: "globe",
			// 		color: "blue",
			// 		isExpanded: false,
			// 		subPages: [],
			// 	},
			// 	moduleId: PLATFORM.moduleName("./pages/panel-grid"),
			// 	nav: true,
			// 	title: "panel-grid",
			// 	config: {},
			// },
		];

		//console.log(user);

		if (user.isAdmin) {
			routes.push({
				route: ["organization-settings"],
				name: "Organization Settings",
				settings: {
					icon: "gear",
					color: "blue",
					isExpanded: false,
					subPages: [],
				},
				moduleId: PLATFORM.moduleName("./pages/organization-settings"),
				nav: false,
				title: "Organization Settings",
				config: {},
			});
		}

		if (
			user.organization.organizationId ==
			"441d2b89-da76-4bcc-a194-69bc14096f92"
		) {
			routes.push({
				route: ["video-admin"],
				name: "Video Admin",
				settings: {
					icon: "map",
					color: "blue",
					isExpanded: false,
					subPages: [],
				},
				moduleId: PLATFORM.moduleName("./pages/video-admin"),
				nav: false,
				title: "Video Admin",
				config: {},
			});
		}

		if (
			user.organization.organizationId ==
			"441d2b89-da76-4bcc-a194-69bc14096f92" &&
			user.isAdmin
		) {
			routes.push({
				route: ["license-admin"],
				name: "License Admin",
				settings: {
					icon: "map",
					color: "blue",
					isExpanded: false,
					subPages: [],
				},
				moduleId: PLATFORM.moduleName("./pages/license-admin"),
				nav: false,
				title: "License Admin",
				config: {},
			});
		}

		//video sidebar route:
		config.map(routes);

		config.fallbackRoute("videos");

		// old sidebar route stuff.

	}
}
