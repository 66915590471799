import { Aurelia } from "aurelia-framework";
import environment from "../config/environment.json";
import { PLATFORM } from "aurelia-pal";
import { initialState } from "./services/state";
import { AuthenticationService } from "services/authentication-service";

export async function configure(aurelia: Aurelia): Promise<void> {
	aurelia.use
		.standardConfiguration()
		// .developmentLogging()
		.feature(PLATFORM.moduleName("resources/index"));

	if (environment.testing) {
		aurelia.use.plugin(PLATFORM.moduleName("aurelia-testing"));
	}

	aurelia.use.plugin(PLATFORM.moduleName("aurelia-dialog"));
	aurelia.use.plugin(PLATFORM.moduleName("aurelia-store"), { initialState });
	aurelia.use.plugin(PLATFORM.moduleName("aurelia-animator-css"));

	await aurelia.start();

	if (AuthenticationService.isLoggedIn()) {
		aurelia.setRoot(PLATFORM.moduleName("app"));
	} else {
		aurelia.setRoot(PLATFORM.moduleName("login-register"));
	}
}
