import { bindingMode, bindable } from "aurelia-framework";

export class TextSelectElement {
    @bindable isEditing: boolean;
    @bindable isEditable: boolean;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    options: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    selectedOption: any;

    dropdownExpanded: boolean = false;
    @bindable rightAligned: boolean = false;

    selectOption(option) {
        if (option == this.selectedOption) return;
        this.selectedOption = option;
        this.dropdownExpanded = false;
    }

    toggleDropdown() {
        this.dropdownExpanded = !this.dropdownExpanded;
    }
    collapseDropdown() {
        this.dropdownExpanded = false;
    }
}
