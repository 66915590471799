export default class License implements ILicense {
    public versionNumber: number;
    public registerModel: KeylokRegisterModel;
    public licenseConfiguration: any;
    public numberOfSeats: number;
    public activationPassword: string;
    public licenseId: string;
    public type: string;
    public number: number;
    public division?: string;
    public comment?: string;
    public organizationName?: string;
    public upgradeFile: string;
    public updateIds?: string[];
    public dongleNumbers?: string[];
    public batchUpdate: boolean = false;

    public organizationId: string;
    public softwareKeyCustomerId?: string;
    public newLicense: boolean = false;
    public static fullEnumValue: number = 65535;

    constructor(params: {
        type: string;
        number?: number;
        licenseData?: any;
        organizationId?: string;
        organizationName?: string;
        comment?: string;
        division?: string;
        updateIds?: string[];
    }) {
        // If licenseData is defined, this isn't a new blank license and needs to be populated
        if (typeof params.number !== "undefined") {
            this.number = params.number;
        }
        if (typeof params.division !== "undefined") {
            this.division = params.division;
        }
        if (typeof params.comment !== "undefined") {
            this.comment = params.comment;
        }
        if (typeof params.organizationName !== "undefined") {
            this.organizationName = params.organizationName;
        }
        if (typeof params.updateIds !== "undefined") {
            this.updateIds = params.updateIds;
        }
        if (typeof params.organizationId !== "undefined") {
            this.organizationId = params.organizationId;
        }

        // If licenseData is defined and the license has RegisterModel or LicenseConfiguration data, populate the license with the data
        if (
            typeof params.licenseData !== "undefined" 
            && ((typeof params.licenseData.registerModel !== "undefined" 
                && params.licenseData.registerModel !== "" 
                && params.licenseData.registerModel !== null)
                || (typeof params.licenseData.licenseConfiguration !== "undefined" 
                && params.licenseData.licenseConfiguration !== "" 
                && params.licenseData.licenseConfiguration !== null))
        ) {
            var tempLicense: ILicense = params.licenseData;
            this.versionNumber = tempLicense.versionNumber;
            this.licenseId = tempLicense.licenseId;
            this.type = params.type;

            // populate Keylok license data
            if (params.type === "Keylok") {
                // Check if the upgrade file is empty and only assign it if it has content
                tempLicense.upgradeFile !== ""
                    ? (this.upgradeFile = tempLicense.upgradeFile)
                    : "";
                this.registerModel = tempLicense.registerModel;

                this.licenseConfiguration =
                    this.registerModelToLicenseConfiguration(
                        this.registerModel
                    );
                this.registerModel.dongleSerialNumber = params.number.toString();
                if ( this.registerModel.expirationDate != null && typeof this.registerModel.expirationDate === "string"){
                    this.registerModel.expirationDate = new Date(
                        this.registerModel.expirationDate.includes(":00Z")
                            ? this.registerModel.expirationDate.replace(":00Z", ":00")
                            : this.registerModel.expirationDate + (this.registerModel.expirationDate.includes(".000Z") ? "" : ".000Z")
                    );
                }
                // populate SoftwareKey license data
            } else {
                if( tempLicense.licenseConfiguration != null){
                    this.licenseConfiguration = new Configuration(Object.assign(new ConfigurationDTO, tempLicense.licenseConfiguration));
                } else {
                    this.licenseConfiguration = new Configuration();
                }
                this.numberOfSeats = tempLicense.numberOfSeats;
                this.activationPassword = tempLicense.activationPassword;
            }
            // If licenseData is defined, but there is no RegisterModel or LicenseConfiguration set
            // create empty RegisterModel and LicenseConfiguration data
        } else if (typeof params.licenseData !== "undefined") {
            var tempLicense: ILicense = params.licenseData;
            this.versionNumber = tempLicense.versionNumber;
            this.licenseId = tempLicense.licenseId;
            this.type = params.type;
            if (typeof params.number !== "undefined") {
                this.number = params.number;
            }
            this.licenseConfiguration = new Configuration();
            if (params.type === "Keylok") {
                this.registerModel = new KeylokRegisterModel();
            } else {
                this.numberOfSeats = 1;
                this.activationPassword = "";
            }
            // If licenseData is undefined it means we are creating a new blank license
        } else {
            this.versionNumber = 1;
            this.newLicense = true;
            this.licenseId = null;
            this.type = params.type;
            this.licenseConfiguration = new Configuration();
            if (params.type === "Keylok") {
                this.registerModel = new KeylokRegisterModel();
            } else {
                this.numberOfSeats = 1;
                this.activationPassword = "";
            }
        }
    }

    // Creates a LicenseConfiguration based off of a RegisterModel. A license
    // configuration stores the same information as the NavViewProduct of the RegisterModel
    registerModelToLicenseConfiguration(
        registerModel: KeylokRegisterModel
    ): Configuration {
        var tempConfig = <Configuration>{};

        // Set feature values in the License Configuration
        var activeFeatures =
            this.getProductActiveFeaturesList("navViewProduct");
        for (var feature of featureList) {
            if (activeFeatures.includes(feature)) {
                tempConfig[feature] = true;
            } else {
                tempConfig[feature] = false;
            }
        }
        // Set the maintenanceEndDate of the License Configuration
        // Search through the register model to find any maintenance date that isn't null
        for (var product of productList) {
            var temp = registerModel[product].maintenanceEndDate;
            if (temp != null) {
                tempConfig.MaintenanceEndDate = new Date(
                    temp.includes(":00Z")
                        ? temp.replace(":00Z", ":00")
                        : temp + (temp.includes(".000Z") ? "" : ".000Z")
                );
                break;
            }
        }
        // Set vehicle limit for the License Configuration
        tempConfig.VehicleLimit =
            registerModel["navViewProduct"]["vehicleLimit"];
        return tempConfig;
    }

    // Get list of all Features from the License Configuration object
    getFeatures(): IFeature[] {
        var features = new Array<IFeature>();
        Object.keys(this.licenseConfiguration).forEach((key) => {
            if (
                !["Enabled", "MaintenanceEndDate", "VehicleLimit"].includes(key)
            ) {
                features.push({
                    name: key,
                    label: FeatureLabels.has(key)
                        ? FeatureLabels.get(key)
                        : key.replace(/([A-Z0-9]+)/g, " $1").trim(),
                    enabled: this.licenseConfiguration[key],
                });
            }
        });
        return features;
    }

    // Get a list of active features for the License Configuration object
    // Format flag will format the features using the FeatureLabels map
    getActiveFeatures(format: boolean = false): string[] {
        var activeFeatures = new Array<string>();
        Object.keys(this.licenseConfiguration).forEach((key) => {
            if (
                !["Enabled", "MaintenanceEndDate", "VehicleLimit"].includes(
                    key
                ) &&
                this.licenseConfiguration[key]
            ) {
                if (format) {
                    activeFeatures.push(
                        FeatureLabels.has(key)
                            ? FeatureLabels.get(key)
                            : key.replace(/([A-Z0-9]+)/g, " $1").trim()
                    );
                } else {
                    activeFeatures.push(key);
                }
            }
        });
        return activeFeatures;
    }

    // Set the active features of the License Configuration object
    setFeatures(features: IFeature[]) {
        for (var feature of features) {
            if (feature.enabled) {
                this.licenseConfiguration[feature.name] = true;
            } else {
                this.licenseConfiguration[feature.name] = false;
            }
        }
    }

    // compare active features with a list of features
    // returns true if features are the same
    compareFeatures(features: IFeature[]): boolean {
        var areEquivalent: boolean = true;
        for (var feature of features) {
            if (this.licenseConfiguration[feature.name] !== feature.enabled) {
                areEquivalent = false;
            }
        }
        return areEquivalent;
    }

    // Get a list of products for a particular license along with whether
    // the product is enabled on the license or not.
    getProducts(): IFeature[] {
        var products = new Array<IFeature>();
        for (var product of productList) {
            products.push({
                name: product,
                label: ProductLabels.get(product),
                enabled:
                    this.type === "Software"
                        ? true
                        : this.registerModel[product]["options"] > 0,
                active: product === "navViewProduct" ? true : false,
            });
            // If it is a Software Key license it only has one product, NavView
            // so exit the loop after first iteration
            if (this.type === "Software") break;
        }
        return products;
    }

    compareProducts(inputProducts: IFeature[]): boolean {
        var isEquivalent: boolean = true;
        for (var licenseProduct of productList) {
            for (var inputProduct of inputProducts) {
                if (inputProduct.name === licenseProduct) {
                    if (
                        this.registerModel[licenseProduct]["options"] > 0 !==
                        inputProduct.enabled
                    ) {
                        isEquivalent = false;
                    }
                }
            }
        }
        return isEquivalent;
    }

    // Get a list of active products from the Register Model object
    getActiveProducts(): string[] {
        var enabledProducts: string[] = new Array<string>();
        for (var [key, value] of Object.entries(this.registerModel)) {
            if (key === "infinityViewProduct" && value["options"] > 0) {
                var state = this.getInfinityViewState();
                enabledProducts.push(
                    ProductLabels.get(key) +
                        " " +
                        state[0].toUpperCase() +
                        state.slice(1)
                );
            } else if (key.includes("Product") && value["options"] > 0) {
                enabledProducts.push(ProductLabels.get(key));
            }
        }
        return enabledProducts;
    }

    // Takes a product name and then returns a list of the features
    // that are active on that product based on the feature values
    // from the RegisterModel
    getProductActiveFeaturesList(product: string): string[] {
        return License.convertFeaturesValueToList(
            this.registerModel[product].features1,
            1
        ).concat(
            License.convertFeaturesValueToList(
                this.registerModel[product].features2,
                2
            )
        );
    }

    // Takes a product names and a list of feature names and updates the
    // feature values for the specificied product based on the list
    setProductFeatureValuesFromList(product: string, features: string[]) {
        var features1: number = 0;
        var features2: number = 0;
        for (var feature of features) {
            if (Object.values(Features1).includes(feature)) {
                features1 = features1 + Features1[feature];
            }
            if (Object.values(Features2).includes(feature)) {
                features2 = features2 + Features2[feature];
            }
        }
        this.registerModel[product].features1 = features1;
        this.registerModel[product].features2 = features2;
    }

    // Convert a value to a list of strings representing features from one
    // of the two features enums
    static convertFeaturesValueToList(
        value: number,
        featureNum: number
    ): string[] {
        if (featureNum === 1) {
            return Object.values(Features1)
                .filter((v) => typeof v === "number" && (value & v) !== 0)
                .map((v) => Features1[v as number]);
        } else if (featureNum === 2) {
            return Object.values(Features2)
                .filter((v) => typeof v === "number" && (value & v) !== 0)
                .map((v) => Features2[v as number]);
        }
    }

    // Convert a list of strings into a value based on one of the feature enums
    static convertFeaturesListToValue(
        features: string[],
        featureNum: number
    ): number {
        if (featureNum === 1) {
            return features.reduce(
                (sum, currentFeature) => sum + Features1[currentFeature],
                0
            );
        } else if (featureNum === 2) {
            return features.reduce(
                (sum, currentFeature) => sum + Features2[currentFeature],
                0
            );
        }
    }

    // Gets a list of all of the feature names from the Feature enums by passing in
    // the maximum enum value to convertFeaturesValueToList
    getAllFeatureNames(): string[] {
        return License.convertFeaturesValueToList(65535, 1).concat(
            License.convertFeaturesValueToList(65535, 2)
        );
    }

    // Function for checking if the current register values for InfinityView
    // would be considered an instance of InfinityViewOffice since it is
    // just a subset of the regular InfinityView product
    getInfinityViewState(): string {
        if (
            this.registerModel.infinityViewProduct.options > 0 &&
            this.registerModel.infinityViewProduct.features1 ===
                productSpecifications["infinityViewOfficeProduct"].features
                    .features1 &&
            this.registerModel.infinityViewProduct.features2 ===
                productSpecifications["infinityViewOfficeProduct"].features
                    .features2
        ) {
            return "office";
        } else if (
            this.registerModel.infinityViewProduct.options > 0 &&
            this.registerModel.infinityViewProduct.features1 ===
                productSpecifications["infinityViewProduct"].features
                    .features1 &&
            this.registerModel.infinityViewProduct.features2 ===
                productSpecifications["infinityViewProduct"].features.features2
        ) {
            return "standard";
        } else if (this.registerModel.infinityViewProduct.options > 0) {
            return "nonstandard"
        } else {
            return "disabled";
        }
    }

    // Get list of all posible InfinityView options
    getInfinityViewOptions(): IFeature[] {
        var options = ["disabled", "nonstandard", "office", "standard"];
        var optionsList = new Array<IFeature>();
        for (var option of options) {
            optionsList.push({
                name: option,
                label: option.charAt(0).toUpperCase() + option.slice(1),
                enabled: false,
            });
        }
        return optionsList;
    }

    // Sets all the products within the Register Model
    setProducts(
        products: IFeature[],
        features: string[],
        maintenanceEndDate: Date,
        vehicleLimit: number,
        infinityViewState: string
    ) {
        for (var product of products) {
            if (product.name === "navViewProduct") {
                this.setProduct(
                    product.name,
                    product.enabled,
                    maintenanceEndDate,
                    false,
                    features,
                    vehicleLimit
                );
            } else if (product.name === "infinityViewProduct") {
                if (infinityViewState === "standard") {
                    this.setProduct(product.name, true, maintenanceEndDate);
                } else if (infinityViewState === "office") {
                    this.setProduct(
                        "infinityViewOfficeProduct",
                        true,
                        maintenanceEndDate
                    );
                } else if (infinityViewState === "nontstandard") {
                    this.setProduct(
                        product.name,
                        true,
                        maintenanceEndDate,
                        true
                    );
                } else {
                    this.setProduct(product.name, false, maintenanceEndDate);
                }
            } else {
                this.setProduct(
                    product.name,
                    product.enabled,
                    maintenanceEndDate
                );
            }
        }
    }

    // Sets the register model values associated with a given product.
    // If the product is enabled, the options register is given the appropriate
    // value based on the "productSpecifications" array
    // For navView, feature values and vehicleLimit are set based on
    // the input features array and vehicleLimit value regardless of if the
    // product is enabled or not
    // For other products that have features, the features values are set to the values
    // given in the product array if the product is enabled
    setProduct(
        product: string,
        enabled: boolean,
        maintenanceEndDate: Date,
        featuresUnchanged?: boolean,
        features?: string[],
        vehicleLimit?: number,
    ) {
        // set the product name to infinityViewProduct if the product is infinityViewOfficeProduct
        // so that the register value will be placed in the appropriate part of the register model
        var alias = product;
        if (product === "infinityViewOfficeProduct") {
            product = "infinityViewProduct";
        }

        // set product enabled status and maintenance end date
        if (enabled) {
            this.registerModel[product].options =
                productSpecifications[alias].options;
            this.registerModel[product].maintenanceEndDate = maintenanceEndDate;
        } else {
            this.registerModel[product].options = 0;
            this.registerModel[product].maintenanceEndDate = null;
        }

        // set feature values on products with features:
        // if navViewProduct, set features to input features
        // if not navView, but enabled, set values to values from productSpecifications
        // if not navView and not enabled, set all features to 0
        if (product === "navViewProduct") {
            this.registerModel[product].vehicleLimit = vehicleLimit;
            this.setProductFeatureValuesFromList(product, features);
        } else if ( featuresUnchanged == true ){
            return;
        } else if (
            productSpecifications[product].hasOwnProperty("features") &&
            enabled
        ) {
            this.registerModel[product].features1 =
                productSpecifications[alias].features.features1;
            this.registerModel[product].features2 =
                productSpecifications[alias].features.features2;
            this.registerModel[product].features3 =
                productSpecifications[alias].features.features3;
        } else if (productSpecifications[product].hasOwnProperty("features")) {
            this.registerModel[product].features1 = 0;
            this.registerModel[product].features2 = 0;
            this.registerModel[product].features3 = 0;
        }
    }
}

interface ILicense {
    versionNumber: number;
    // registerModel and licenseConfiguration accepts any type here because the server response
    // the server response returns the registerModel and licenseConfiguration as a string
    registerModel?: KeylokRegisterModel;
    licenseConfiguration?: any;
    numberOfSeats?: number;
    upgradeFile?: string;
    activationPassword?: string;
    licenseId: string;
    division?: string;
    comment?: string;
}

// The KeylokRegisterModel represents the license configuration information stored on physical dongles
// The register model is made up of license and dongle metadata and then data for specific products.
// The dongle is a simple set of registers with values set to represent what products and
// features of those products a customer is able to access
class KeylokRegisterModel {
    dongleSerialNumber: string = "0";
    globalUniqueId: string = "";
    maxNetworkSessions: number = 0;
    remarks: string = "";
    companyName: string = "";
    expirationDate: any;
    navViewProduct: NavViewProduct = new NavViewProduct("NavView", 50);
    rigNavProduct: NavViewProduct = new NavViewProduct("RigNav", 100);
    infinityViewProduct: NavViewProduct = new NavViewProduct(
        "InfinityView",
        200
    );
    gnssViewProduct: NavViewProduct = new NavViewProduct("GnssView", 250);
    metrologyCalculatorProduct: Product = new Product(
        "Metrology Calculator",
        0
    );
    remoteLBLProduct: Product = new Product("Remote LBL", 400);
}

class Product {
    constructor(name: string, startAddress: number) {
        this.name = name;
        this.startAddress = startAddress;
    }
    name: string;
    startAddress: number;
    options: number = 0;
    maintenanceEndDate: Date = undefined;
}

class NavViewProduct extends Product {
    vehicleLimit: number = 0;
    features1: number = 0;
    features2: number = 0;
    features3: number = 0;
    features1Mask: number = 0;
    features2Mask: number = 0;
    features3Mask: number = 0;
}

interface IFeature {
    name: string;
    label: string;
    enabled: boolean;
    active?: boolean;
}

// Enums for features values contained in the registerModel
// Correspond to the ones used for programming physical dongle
enum Features1 {
    None = 0,
    RealTimeDeviceIO = 1 << 0,
    NetworkServices = 1 << 1,
    //Reserved2 = 1 << 2,
    AnchorManagement = 1 << 3,
    AHV = 1 << 4,
    OrcaFlex = 1 << 5,
    Sonardyne6G = 1 << 6,
    AdcpProfiling = 1 << 7,
    LaserProfiling = 1 << 8,
    PipeLay = 1 << 9,
    LazyWaveRiserMonitoring = 1 << 10,
    CableExitMonitoring = 1 << 11,
    PipeExitMonitoring = 1 << 12,
    NodeDashboard = 1 << 13,
    MissionPlanning = 1 << 14,
    MissionControl = 1 << 15,
}

enum Features2 {
    None = 0,
    Auv = 1 << 0,
    AdjustmentQc = 1 << 1,
    UsblCalibration = 1 << 2,
    DvlCalibration = 1 << 3,
    VeriposQc = 1 << 4,
    DepthMonitoring = 1 << 5,
    PoseEstimation = 1 << 6,
    SeismicQC = 1 << 7,
    AssetManagement = 1 << 8,
    SubseaStructures = 1 << 9,
    SimOps = 1 << 10,
    WanNetworkServices = 1 << 11,
    Flowit = 1 << 12,
    VisualPosition = 1 << 13,
    //Reserved14 = 1 << 14,
    //Reserved15 = 1 << 15,
}

// Features3 may be used in the future for adding more NavView features
enum Features3 {}

// Enumerators which specify the possible values for the options register
// for each of the products. ProductOptions applies to all NavView products
enum ProductOptions {
    None = 0,
    Enabled = 1 << 0,
}

enum MetrologyCalculatorOptions {
    None = 0,
    MetrologyCalculations = 1 << 0,
    NetworkCalculations = 1 << 1,
}

enum RemoteLBLProductOptions {
    None = 0,
    Enabled = 1 << 0,
}

// List of options and features for each of the products on the dongle based on the
// Feature and Options enumerators above. When setProduct() is called the register
// values for the input product options and features are set to these specifications
const productSpecifications: {
    [name: string]: {
        options: number;
        features?: { features1: number; features2: number; features3: number };
    };
} = {
    navViewProduct: {
        options: ProductOptions.Enabled,
    },

    rigNavProduct: {
        options: ProductOptions.Enabled,
        features: {
            features1:
                Features1.RealTimeDeviceIO +
                Features1.NetworkServices +
                Features1.AdcpProfiling,
            features2: Features2.SubseaStructures,
            features3: 0,
        },
    },

    infinityViewOfficeProduct: {
        options: ProductOptions.Enabled,
        features: {
            features1: Features1.MissionPlanning,
            features2: Features2.UsblCalibration,
            features3: 0,
        },
    },

    infinityViewProduct: {
        options: ProductOptions.Enabled,
        features: {
            features1:
                Features1.RealTimeDeviceIO +
                Features1.NetworkServices +
                Features1.MissionPlanning +
                Features1.MissionControl,
            features2: Features2.UsblCalibration + Features2.SubseaStructures,
            features3: 0,
        },
    },

    gnssViewProduct: {
        options: ProductOptions.Enabled,
        features: {
            features1: Features1.RealTimeDeviceIO,
            features2: Features2.VeriposQc,
            features3: 0,
        },
    },

    metrologyCalculatorProduct: {
        options:
            MetrologyCalculatorOptions.MetrologyCalculations +
            MetrologyCalculatorOptions.NetworkCalculations,
    },

    remoteLBLProduct: {
        options: RemoteLBLProductOptions.Enabled,
    },
};

class ConfigurationDTO{
    enabled: boolean = true;
    realTimeDeviceIO: boolean = false;
    networkServices: boolean = false;
    usblCalibration: boolean = false;
    dvlCalibration: boolean = false;
    adcpProfiling: boolean = false;
    subseaStructures: boolean = false;

    adjustmentQc: boolean = false;
    ahv: boolean = false;
    anchorManagement: boolean = false;
    assetManagement: boolean = false;
    auv: boolean = false;
    cableExitMonitoring: boolean = false;

    depthMonitoring: boolean = false;
    flowit: boolean = false;
    laserProfiling: boolean = false;
    lazyWaveRiserMonitoring: boolean = false;
    missionControl: boolean = false;
    missionPlanning: boolean = false;

    nodeDashboard: boolean = false;
    orcaFlex: boolean = false;
    pipeExitMonitoring: boolean = false;
    pipeLay: boolean = false;
    poseEstimation: boolean = false;
    seismicQC: boolean = false;

    simOps: boolean = false;
    sonardyne6G: boolean = false;
    veriposQc: boolean = false;
    visualPosition: boolean = false;
    wanNetworkServices: boolean = false;

    vehicleLimit: number = 1;
    maintenanceEndDate: string;
}

// Contains all the information relevant to a License Configuration
//  associated with a software key license
class Configuration {
    constructor (dto?: ConfigurationDTO){
        if (typeof dto !== "undefined")
        {
            this.Enabled = dto.enabled;
            this.RealTimeDeviceIO = dto.realTimeDeviceIO;
            this.NetworkServices = dto.networkServices;
            this.UsblCalibration = dto.usblCalibration;
            this.DvlCalibration = dto.dvlCalibration;
            this.AdcpProfiling = dto.adcpProfiling;
            this.SubseaStructures = dto.subseaStructures;
        
            this.AdjustmentQc = dto.adjustmentQc;
            this.AHV = dto.ahv;
            this.AnchorManagement = dto.anchorManagement;
            this.AssetManagement = dto.assetManagement;
            this.Auv = dto.auv;
            this.CableExitMonitoring = dto.cableExitMonitoring;
        
            this.DepthMonitoring = dto.depthMonitoring;
            this.Flowit = dto.flowit;
            this.LaserProfiling = dto.laserProfiling;
            this.LazyWaveRiserMonitoring = dto.lazyWaveRiserMonitoring;
            this.MissionControl = dto.missionControl;
            this.MissionPlanning = dto.missionPlanning;
        
            this.NodeDashboard = dto.nodeDashboard;
            this.OrcaFlex = dto.orcaFlex;
            this.PipeExitMonitoring = dto.pipeExitMonitoring;
            this.PipeLay = dto.pipeLay;
            this.PoseEstimation = dto.poseEstimation;
            this.SeismicQC = dto.seismicQC;
        
            this.SimOps = dto.simOps;
            this.Sonardyne6G = dto.sonardyne6G;
            this.VeriposQc = dto.veriposQc;
            this.VisualPosition = dto.visualPosition;
            this.WanNetworkServices = dto.wanNetworkServices;
        
            this.VehicleLimit = dto.vehicleLimit;
            this.MaintenanceEndDate = new Date(
                dto.maintenanceEndDate.includes(":00Z")
                    ? dto.maintenanceEndDate.replace(":00Z", ":00")
                    : dto.maintenanceEndDate + (dto.maintenanceEndDate.includes(".000Z") ? "" : ".000Z")
            );
        }
    }

    Enabled: boolean = true;
    RealTimeDeviceIO: boolean = false;
    NetworkServices: boolean = false;
    UsblCalibration: boolean = false;
    DvlCalibration: boolean = false;
    AdcpProfiling: boolean = false;
    SubseaStructures: boolean = false;

    AdjustmentQc: boolean = false;
    AHV: boolean = false;
    AnchorManagement: boolean = false;
    AssetManagement: boolean = false;
    Auv: boolean = false;
    CableExitMonitoring: boolean = false;

    DepthMonitoring: boolean = false;
    Flowit: boolean = false;
    LaserProfiling: boolean = false;
    LazyWaveRiserMonitoring: boolean = false;
    MissionControl: boolean = false;
    MissionPlanning: boolean = false;

    NodeDashboard: boolean = false;
    OrcaFlex: boolean = false;
    PipeExitMonitoring: boolean = false;
    PipeLay: boolean = false;
    PoseEstimation: boolean = false;
    SeismicQC: boolean = false;

    SimOps: boolean = false;
    Sonardyne6G: boolean = false;
    VeriposQc: boolean = false;
    VisualPosition: boolean = false;
    WanNetworkServices: boolean = false;

    VehicleLimit: number = 1;
    MaintenanceEndDate: Date;
}

// List of all product contained in a Dongle license
const productList: string[] = [
    "navViewProduct",
    "rigNavProduct",
    "infinityViewProduct",
    "gnssViewProduct",
    "metrologyCalculatorProduct",
    "remoteLBLProduct",
];

const featureList: string[] = [
    "RealTimeDeviceIO",
    "NetworkServices",
    "UsblCalibration",
    "DvlCalibration",
    "AdcpProfiling",
    "SubseaStructures",

    "AdjustmentQc",
    "AHV",
    "AnchorManagement",
    "AssetManagement",
    "Auv",
    "CableExitMonitoring",

    "DepthMonitoring",
    "Flowit",
    "LaserProfiling",
    "LazyWaveRiserMonitoring",
    "MissionControl",
    "MissionPlanning",

    "NodeDashboard",
    "OrcaFlex",
    "PipeExitMonitoring",
    "PipeLay",
    "PoseEstimation",
    "SeismicQC",

    "SimOps",
    "Sonardyne6G",
    "VeriposQc",
    "VisualPosition",
    "WanNetworkServices",
];

// Alternate labels for feature and product names
// Takes the place of "Description"s in C# enum
export const FeatureLabels = new Map<string, string>([
    ["RealTimeDeviceIO", "Real-time IO"],
    ["AnchorManagement", "Anchor Management"],
    ["LazyWaveRiserMonitoring", "Lazy Wave"],
    ["CableExitMonitoring", "CEMS"],
    ["PipeExitMonitoring", "PEMS"],
]);

export const ProductLabels = new Map<string, string>([
    ["navViewProduct", "NavView"],
    ["rigNavProduct", "RigNav"],
    ["infinityViewProduct", "InfinityView"],
    ["gnssViewProduct", "GnssView"],
    ["metrologyCalculatorProduct", "Metrology Calculator"],
    ["remoteLBLProduct", "Remote LBL"],
]);

export const SoftwareKeyProducts = new Map<string, string>([
    ["33455", "NavView Volume License 120 Day"],
    ["34313", "NavView Volume License 15 Day"],
    ["33380", "NavView Volume License 30 Day"],
    ["31141", "Volume License"],
    ["33381", "Volume LIcense 30 day checkout"],
    ["36268", "Base Application"],
]);
