import { bindable, bindingMode } from "aurelia-framework";

export class FilterSelectElement {
	@bindable isEditable: boolean;
	@bindable isEditing: boolean;
	@bindable darkUi: boolean;

	@bindable({ defaultBindingMode: bindingMode.twoWay })
	selectedOptions: Array<any> = [];
	@bindable options: Array<any>;
	@bindable filterTitle: string;
	@bindable optionDisplayProperty: string;

	@bindable optionKey: string;

	@bindable selectFunctionCallback: Function;

	dropdownExpanded: boolean = false;
	filteredOptions: Array<any> = [];

	selectedOptionsChanged(newValue, oldValue) {
		if (newValue == oldValue) return;

		this.selectIntialOptions();
	}

	async attached() {
		await this.filterSelectedOptions();

		this.selectIntialOptions();
	}

	toggleDropdown() {
		this.dropdownExpanded = !this.dropdownExpanded;
	}

	selectOption(option) {
		var optionIndex = this.selectedOptions.findIndex((selectedOption) => {
			return selectedOption[this.optionKey] == option[this.optionKey];
		});

		if (optionIndex != -1) {
			this.selectedOptions.splice(optionIndex, 1);
			option.isSelected = false;

			this.selectFunctionCallback();
			return;
		}
		this.selectedOptions.push(option);
		option.isSelected = true;

		this.selectFunctionCallback();
	}

	removeOption(index, selectedOption) {
		// console.log("Selected Option", selectedOption);
		var selectableOption = null;
		selectableOption.isDisabled = false;
		this.selectedOptions.splice(index, 1);
	}

	async filterSelectedOptions() {
		if (this.options == null) return;

		this.options.forEach((option) => {
			this.selectedOptions.forEach((selectedOption) => {
				if (option == selectedOption) {
					option.isDisabled = true;
				} else {
					option.isDisabled = false;
				}
			});
		});
	}

	selectIntialOptions() {
		if (this.options == null) return;

		if (this.selectedOptions == null) return;

		var self = this;

		this.options.forEach((option) => {
			this.selectedOptions.forEach((selectedOption) => {
				if (option[self.optionKey] == selectedOption[self.optionKey]) {
					option.isSelected = true;
				}
			});
		});

		// console.log("Selected Options", this.selectedOptions);
		// console.log(this.options);
	}

    optionIsSelected(option) {

		var self = this;

		return this.selectedOptions.some((selectedOption) => {
			return option[self.optionKey] == selectedOption[self.optionKey];
		});
	}

	closeDropdown() {
		this.dropdownExpanded = false;
	}

	unselectAll() {
		this.selectedOptions = [];
		this.options.forEach((option) => {
			option.isSelected = false;
		});
		this.selectFunctionCallback();
	}
}
