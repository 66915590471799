import { bindable, bindingMode, observable } from "aurelia-framework";

export class ObjectMultiSelectElement {
    @bindable isEditable: boolean;
    @bindable isEditing: boolean;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    selectedOptions: Array<any> = [];
    @bindable options: Array<any>;
    @bindable displaykey: string;
    @bindable selectableOptions: Array<any> = [];
    @bindable filterTitle: string;

    dropdownExpanded: boolean = false;
    filteredOptions: Array<any> = [];

    async attached() {
        await this.filterSelectedOptions();

        if (this.selectedOptions.length == 0) {
            this.options.forEach((option) => {
                this.selectableOptions.push({
                    name: option,
                    isDisabled: false,
                });
            });
        }
    }

    toggleDropdown() {
        this.dropdownExpanded = !this.dropdownExpanded;
    }

    selectOption(option) {
        if (option.isSelected) {
            this.removeOption(
                this.selectedOptions.indexOf(option.name),
                option.name
            );
            option.isSelected = false;
            return;
        }
        this.selectedOptions.push(option.name);
        option.isSelected = true;
    }

    removeOption(index, selectedOption) {
        var selectableOption = null;
        this.selectableOptions.forEach((option) => {
            if (option.name == selectedOption) {
                selectableOption = option;
            }
        });

        selectableOption.isDisabled = false;

        this.selectedOptions.splice(index, 1);
    }

    async filterSelectedOptions() {
        if (this.selectedOptions && this.selectedOptions.length == 0) return;
        this.options.forEach((option) => {
            this.selectedOptions.forEach((selectedOption) => {
                if (option == selectedOption) {
                    option.isDisabled = true;
                } else {
                    option.isDisabled = false;
                }
            });
        });
    }
}
