export class StringToIntValueConverter {
  fromView(value) {
    if (value != null) {
      var intToReturn = parseInt(value, 10);

      if (!Number.isNaN(intToReturn) && typeof intToReturn == "number") {
        return intToReturn;
      } else {
        return null;
      }
    }
  }

  toView(value) {
    if (value == 0) {
      return null;
    } else {
      return value;
    }
  }
}
